/**
 * This mixin implements CRUD using API calls to Laravel
 */
export default {
  data() {
    return {
      apiQuery: "/api/",
      showDeleteDialog: false,
      loading: false,
      total: 0,
      items: [],
      options: {},
      meta: {},
      filters: {},
      errorMessage: null,
    }
  },

  watch: {
    filters: {
      handler() {
        this.loading = true
        this.get(this.apiQuery, { ...this.filters, ...this.options } )
          .then((data) => {
            if (data.hasOwnProperty.call(data, "data")) {
              this.items = data.data
              this.total = data.total
            } else {
              this.items = data
              this.total = data.length
            }
            this.loading = false
          })
          .catch((error) => {
            alert(error.message)
            this.loading = false
            this.$emit("serverError", error)
          })
      },
      deep: true,
    }, 
    options: {
      handler() {
        this.loading = true
        this.get(this.apiQuery, { ...this.filters, ...this.options })
          .then((data) => {
            if (data.hasOwnProperty.call(data, "data")) {
              this.items = data.data
              this.total = data.total
            } else {
              this.items = data
              this.total = data.length
            }
            this.loading = false
          })
          .catch((error) => {
            alert(error.message)
            this.loading = false
            this.$emit("serverError", error)
          })
      },
      deep: true,
    },
  },

  methods: {
    onAdd() {
      this.$refs.editForm.addItem()
    },

    onEdit(item) {
      this.$refs.editForm.editItem(item, this.items.indexOf(item))
    },

    onSaved(item, index) {
      if (index === -1) {
        this.items.push(item)
        this.total++
      } else {
        Object.assign(this.items[index], item)
      }
      window.setTimeout(() => {
        this.$refs.editForm.closeForm()
      }, 2500)
    },

    onDelete(item) {
      this.$refs.deleteConfirm.deleteItem(item, this.items.indexOf(item))
    },

    onDeleted(item, index) {
      this.items.splice(index, 1)
      this.total--
    },
  },
}
