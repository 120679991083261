<template>
  <v-dialog v-model="showDialog" max-width="30vw">
    <v-card>
      <v-card-title class="headline">{{title}}</v-card-title>

      <v-card-text>
        <slot v-bind="itemToDelete">Are you sure you want to delete this item?</slot>
      </v-card-text>

      <v-card-actions>
        <slot name="buttons">
          <v-spacer></v-spacer>
          <v-btn
            color="gray darken-1"
            :disabled="sending || deletedItem !=null"
            @click="showDialog = false"
          >No</v-btn>
          <v-btn
            color="red darken-1"
            :disabled="sending || deletedItem !=null"
            @click="performDelete"
          >Yes</v-btn>
        </slot>
      </v-card-actions>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <slot :message="errorMessage" name="error">
        <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{errorMessage}}</v-alert>
      </slot>
      <slot v-bind="deletedItem" name="confirmed">
        <v-alert v-if="deletedItem" tile type="success" class="mb-0">{{confirmation}}</v-alert>
      </slot>
    </v-card>
  </v-dialog>
</template>

<script>

  import crudMixin from 'mixins/crudDelete'

  export default {
    mixins: [crudMixin],
    props: {
      url: {
        type: String,
        default: '/api/',
      },
      title: {
        type: String,
        default: 'Delete item',
      },
      confirmation: {
        type: String,
        default: 'Item successfully deleted',
      },
    },

    created () {
      this.apiQuery = this.url
    },

  }
</script>
