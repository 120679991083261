/**
 * This mixin implements CRUD using API calls to Laravel
 */
export default {
  data () {
    return {
      showDialog: false,
      errorMessage: null,
      itemToDelete: null,
      deletedItem: null,
      deletedIndex: -1,
    }
  },

  methods: {

    closeForm () {
      this.showDialog = false
    },

    deleteItem (item, index) {
      this.errorMessage = null
      this.itemToDelete = item
      this.deletedIndex = index
      this.showDialog = true
    },

    performDelete () {
      if (this.deletedIndex >= 0) {
        this.delete(this.apiQuery, this.itemToDelete.id)
          .then(() => {
            this.sending = false
            this.deletedItem = this.itemToDelete
            this.itemToDelete = null
            this.$emit('itemDeleted', this.deletedItem, this.deletedIndex)
            window.setTimeout(() => {
              this.deletedItem = null
              this.deletedIndex = null
              this.showDialog = false
            }, 1500)
          }).catch(error => {
            this.errorMessage = error.message
            this.$emit('serverError', error)
          })
      }
    },
  },
}
