<template>
  <v-container id="user-roles-table" fluid tag="section">
    <base-material-card
      icon="mdi-account-key"
      :title="$t('roles and permissions.table.roles title')"
    >
      <roles-form ref="editForm" :data="meta" @itemSaved="onSaved"></roles-form>
      <delete-dialog
        ref="deleteConfirm"
        :url="apiQuery"
        :title="$t('roles and permissions.delete role')"
        @itemDeleted="onDeleted"
      >
        <template v-slot:default="item">
          Are you sure you want to delete role
          <strong>{{ item.name }}</strong
          >?
        </template>

        <template v-slot:error="error">
          <v-alert v-if="error.message" tile class="mb-0" type="error">
            {{ error.message }}
          </v-alert>
        </template>

        <template v-slot:confirmed="item">
          <v-alert v-if="item.id" tile type="success" class="mb-0"
            >Role {{ item.name }} deleted</v-alert
          >
        </template>
      </delete-dialog>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        multi-sort
        :footer-props="{
          'items-per-page-options': [10, 20, 50],
        }"
      >
        <template v-slot:item.id="{ item }">
          <v-chip v-if="item.isNew" color="green">{{ item.id }}</v-chip>
          <span v-else>{{ item.id }}</span>
        </template>

        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-spacer />
            <v-btn color="primary" dark class="mb-2" @click="onAdd">
              <v-icon left>mdi-account-plus</v-icon>
              {{ $t('roles and permissions.add role') }}
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="onEdit(item)">mdi-pencil</v-icon>
          <v-icon small @click="onDelete(item)">mdi-delete</v-icon>
        </template>

        <template v-slot:item.permissions="{ item }">
          <v-chip
            v-for="permission in item.permissions"
            :key="'permission-' + item.id + '-' + permission"
            small
          >
            {{ meta[permission].name }}
          </v-chip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import crudMixin from 'mixins/crudTable'
import RolesForm from '../forms/RolesForm'
import DeleteDialog from '@/components/crud/DeleteDialog'

export default {
  components: {
    RolesForm,
    DeleteDialog,
  },
  mixins: [crudMixin],
  watch: {
    newPermissionAdded() {
      this.meta = this.$store.getters.getPermissionsArray
      this.$store.commit('SET_NEW_PERMISSION_ADDED', false)
    },
  },
  data() {
    return {
      apiQuery: '/api/roles/',
      /* TODO: Add locales for the table headers */
      headers: [
        { text: 'ID', align: 'start', value: 'id' },
        { text: this.$t('roles and permissions.table.name'), value: 'name' },
        {
          text: this.$t('roles and permissions.table.guard name'),
          value: 'guard_name',
        },
        {
          text: this.$t('roles and permissions.table.permissions'),
          value: 'permissions',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    }
  },
  created() {
    this.get(this.apiQuery + 'create/').then((data) => {
      this.meta = data.permissions
    })
  },
  computed: {
    newPermissionAdded() {
      return this.$store.state.rolesAndPermissions.newPermissionAdded
    },
  },
  mounted() {},
  methods: {
    onSaved(item, index) {
      if (index === -1) {
        this.total++
        item.isNew = true
        this.items.push(item)
        window.setTimeout(() => {
          this.$refs.editForm.clearForm()
        }, 1500)
      } else {
        Object.assign(this.items[index], item)
        window.setTimeout(() => {
          this.$refs.editForm.closeForm()
        }, 2500)
      }
    },
  },
}
</script>
